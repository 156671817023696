<template>
<div class="layui-layout-body   pace-done pace-done">
    <div class="pace  pace-inactive pace-inactive">
        <div class="pace-progress" data-progress-text="100%" data-progress="99"
            style="transform: translate3d(100%, 0px, 0px);">
            <div class="pace-progress-inner"></div>
        </div>
        <div class="pace-activity"></div>
    </div>
    <div class="layui-layout layui-layout-admin layui-layout-left-hide"><!-- 顶部菜单 开始 -->
   <!-- 主体内容 开始 -->
        <div class="layui-body layui-bg-gray">
            <div class="layui-card layui-bg-gray">

                <div class="layui-card-body layui-anim layui-anim-upbit">

                    <div class="think-box-shadow store-total-container">
                        <h4 style="text-align: center">当地时间： {{tableData.localTime}}</h4>
                        <div class="margin-bottom-15">{{tableData.title}}</div>
                        <div class="layui-row layui-col-space15" id="shop_content">
                            <div class="layui-col-sm6 layui-col-md3">
                                <div class="store-total-item nowrap"
                                    style="background:linear-gradient(-125deg,#57bdbf,#2f9de2)">
                                    <div>首冲人数</div>
                                    <div>{{tableData.firstTopUpTotal}}</div>
                                    <div>今日首冲人数 <span class="num2">{{tableData.todayFirstTopUpTotal}}</span></div>
                                    <p>昨日首冲人数 <span class="num3">{{tableData.yesterdayFirstTopUpTotal}}</span></p>
                                </div><i class="store-total-icon layui-icon layui-icon-template-1"></i>
                            </div>
                            <div class="layui-col-sm6 layui-col-md3">
                                <div class="store-total-item nowrap"
                                    style="background:linear-gradient(-125deg,#ff7d7d,#fb2c95)">
                                    <div>用户总量</div>
                                    <div>{{tableData.userTotal}}</div>
                                    <div>今日新增用户 <span class="num2">{{tableData.todayUserAdd}}</span></div>
                                    <p>昨日新增用户 <span class="num3">{{tableData.yesterdayUserAdd}}</span></p>
                                </div><i class="store-total-icon layui-icon layui-icon-user"></i>
                            </div>
                            <div class="layui-col-sm6 layui-col-md3">
                                <div class="store-total-item nowrap"
                                    style="background:linear-gradient(-113deg,#c543d8,#925cc3)">
                                    <div>订单总量</div>
                                    <div>{{tableData.orderTotal}}</div>
                                    <div>今日新增订单 <span class="num2">{{tableData.todayOrderAdd}}</span></div>
                                    <p>昨日新增订单 <span class="num3">{{tableData.yesterdayOrderAdd}}</span></p>
                                </div><i class="store-total-icon layui-icon layui-icon-read"></i>
                            </div>
                            <div class="layui-col-sm6 layui-col-md3">
                                <div class="store-total-item nowrap"
                                    style="background: linear-gradient(-113deg,#8e8cb3,#2219d0);">
                                    <div>订单总金额</div>
                                    <div>{{tableData.orderTotalBalance}}</div>
                                    <div>今日新增订单总金额 <span class="num2">{{tableData.todayOrderTotalBalance}}</span></div>
                                    <p>昨日新增订单总金额 <span class="num3">{{tableData.yesterdayOrderTotalBalance}}</span></p>
                                </div><i class="store-total-icon layui-icon layui-icon-rmb"></i>
                            </div>
                            <div class="layui-col-sm6 layui-col-md3">
                                <div class="store-total-item nowrap"
                                    style="background:linear-gradient(-141deg,#ecca1b,#f39526)">
                                    <div>用户充值</div>
                                    <div>{{tableData.userTopUpBalance}}</div>
                                    <div>今日新增充值 <span class="num2">{{tableData.todayTopUpBalance}}</span></div>
                                    <p>昨日新增充值 <span class="num3">{{tableData.yesterdayTopUpBalance}}</span></p>
                                </div><i class="store-total-icon layui-icon layui-icon-survey"></i>
                            </div>
                            <div class="layui-col-sm6 layui-col-md3">
                                <div class="store-total-item nowrap"
                                    style="background:linear-gradient(-141deg,#ecca1b,#f39526)">
                                    <div>充值人数</div>
                                    <div>{{tableData.userTopUpCount}}</div>
                                    <div>今日充值人数 <span class="num2">{{tableData.todayUserTopUpCount}}</span></div>
                                    <p>昨日充值人数 <span class="num3">{{tableData.yesterdayUserTopUpCount}}</span></p>
                                </div><i class="store-total-icon layui-icon layui-icon-survey"></i>
                            </div>
                            <div class="layui-col-sm6 layui-col-md3">
                                <div class="store-total-item nowrap"
                                    style="background: linear-gradient(-141deg,#ec4b1b,#f39526);">
                                    <div>用户提现</div>
                                    <div>{{tableData.userWithdrawalBalance}}</div>
                                    <div>今日新增提现 <span class="num2">{{tableData.todayUserWithdrawalBalance}}</span></div>
                                    <p>昨日新增提现 <span class="num3">{{tableData.yesterdayUserWithdrawalBalance}}</span></p>
                                </div><i class="store-total-icon layui-icon layui-icon-dollar"></i>
                            </div>
                            <div class="layui-col-sm6 layui-col-md3">
                                <div class="store-total-item nowrap"
                                    style="background: linear-gradient(-141deg,#ec4b1b,#f39526);">
                                    <div>提现人数</div>
                                    <div>{{tableData.userWithdrawalCount}}</div>
                                    <div>今日提现人数 <span class="num2">{{tableData.todayUserWithdrawalCount}}</span></div>
                                    <p>昨日提现人数 <span class="num3">{{tableData.yesterdayUserWithdrawalCount}}</span></p>
                                </div><i class="store-total-icon layui-icon layui-icon-dollar"></i>
                            </div>
                            <div class="layui-col-sm6 layui-col-md3">
                                <div class="store-total-item nowrap"
                                    style="background: linear-gradient(-141deg,#963064,#8a0920);">
                                    <div>月充值</div>
                                    <div>{{tableData.monthTopUpBalance}}</div>
                                    <div>本月充值 <span class="num2">{{tableData.thisMonthTopUpBalance}}</span></div>
                                    <p>上月充值 <span class="num3">{{tableData.lastMonthTopUpBalance}}</span></p>
                                </div><i class="store-total-icon layui-icon layui-icon-survey"></i>
                            </div>
                            <div class="layui-col-sm6 layui-col-md3">
                                <div class="store-total-item nowrap"
                                    style="background: linear-gradient(-141deg,#1bec78,#155623);">
                                    <div>月提现</div>
                                    <div>{{tableData.monthWithdrawalBalance}}</div>
                                    <div>本月提现 <span class="num2">{{tableData.thisMonthWithdrawalBalance}}</span></div>
                                    <p>上月提现 <span class="num3">{{tableData.lastMonthWithdrawalBalance}}</span></p>
                                </div><i class="store-total-icon layui-icon layui-icon-dollar"></i>
                            </div>
                            <div class="layui-col-sm6 layui-col-md3">
                                <div class="store-total-item nowrap"
                                    style="background: linear-gradient(-141deg,#ea7575,#abd6c6);">
                                    <div>下级返佣</div>
                                    <div>{{tableData.levelCommission}}</div>
                                    <div>今日新增佣金 <span class="num2">{{tableData.todayLevelCommission}}</span></div>
                                    <p>昨日新增佣金 <span class="num3">{{tableData.yesterdayLevelCommission}}</span></p>
                                </div><i class="store-total-icon layui-icon layui-icon-survey"></i>
                            </div>
                            <div class="layui-col-sm6 layui-col-md3">
                                <div class="store-total-item nowrap"
                                    style="background: linear-gradient(-141deg,#2f3331,#565115);">
                                    <div>用户总余额</div>
                                    <div style="font-size: 14px">{{tableData.userTotalBalance}}</div>
                                    <div>今日利息宝转出 <span class="num2">{{tableData.userTotalRatesRollOut}}</span></div>
                                    <p>今日利息宝收益 <span class="num3">{{tableData.userTotalRates}}</span></p>
                                </div><i class="store-total-icon layui-icon layui-icon-dollar"></i>
                            </div>
                        </div>
                    </div>



                    <div v-if="!notShow"  id="agent_content" style="margin-left: 52px">


                      <el-form
                        :model="formAdd"
                        class="form-style"
                        size="mini"
                        :inline="true">



                        <el-form-item label="统计时间">
                          <el-date-picker
                            v-model="formAdd.startTime"
                            type="datetime"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请选择统计时间">
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                          <el-button  >搜索</el-button>
                        </el-form-item>

                      </el-form>

                      <TableBody ref="tableBody">
                        <el-table
                          ref="table"
                          v-loading="tableLoading"
                          :data="tableDataList"
                          :header-cell-style="tableConfig.headerCellStyle"
                          :size="tableConfig.size"
                          :stripe="tableConfig.stripe"
                          :border="false">


                          <el-table-column  label="姓名" prop="" align="center"></el-table-column>
                          <el-table-column  label="客服数量" prop="" align="center"></el-table-column>
                          <el-table-column  label="累计用户" prop="" align="center"></el-table-column>
                          <el-table-column  label="团队余额" prop="" align="center"></el-table-column>
                          <el-table-column  label="今日充值" prop="" align="center"></el-table-column>
                          <el-table-column  label="累计充值" prop="" align="center"></el-table-column>
                          <el-table-column  label="今日提现" prop="" align="center"></el-table-column>
                          <el-table-column  label="今日提现（去手续费）" prop="" align="center"></el-table-column>
                          <el-table-column  label="累计提现" prop="" align="center"></el-table-column>
                          <el-table-column  label="累计提现（去手续费）" prop="" align="center"></el-table-column>




                        </el-table>

                        <!-- 分页区域 -->
                        <!--<div class="serviceManage-page">-->
                        <!--  <el-pagination-->
                        <!--   -->
                        <!--    :current-page="pagination.page"-->
                        <!--    :page-size="pagination.size"-->
                        <!--    :page-sizes="[10, 20, 30, 40]"-->
                        <!--    layout="total, sizes, prev, pager, next, jumper"-->
                        <!--    :total="pagination.total">-->
                        <!--  </el-pagination>-->
                        <!--</div>-->

                      </TableBody>



                    </div>

                </div>
            </div>

        </div><!-- 主体内容 结束 -->



    </div>


    <!--<div class="layui-layer-move"></div>-->

</div>
</template>

<script>
import TableMixin, {PageModelMixin} from '@/mixins/TableMixin'
import "../../assets/adminAssets/files/fonts.css"
import "../../assets/adminAssets/files/layui.css"
import "../../assets/adminAssets/files/console.css"
import "../../assets/adminAssets/files/login.css"
import "../../assets/adminAssets/files/laydate.css"
import "../../assets/adminAssets/files/layer.css"
import "../../assets/adminAssets/files/code.css"
import { indexReport } from '../../api/index'
import {DeleteItemsMixin, GetDataMixin} from "@/mixins/ActionMixin";

export default {
  name: 'Department',
  mixins: [TableMixin, PageModelMixin],


  data() {
    return {
      tableData: {},
      notShow: false,
      tableDataList: [],
      formAdd: {},
      time: '',

      pagination: {
        page: 1,
        size: 10,
        total: ''
      }
    }
  },
  computed: {
  },
  mounted() {
    this.notShow = window.localStorage.isAdmin != '1'
    indexReport().then(res => {
      this.tableData = res.data.result
    })
  }
}
</script>

<style lang="scss" scoped>
   .layui-badge {
            border-radius: 50%;
            top: 10px !important;
        }

        ::-webkit-scrollbar {
            height: 11px !important;
        }

          .layui-tab-card>.layui-tab-title .layui-this {
                        background-color: #fff;
                    }

                                         .store-total-container {
                                            margin-left: 50px;
                            font-size: 14px;
                            margin-bottom: 20px;
                            letter-spacing: 1px;
                        }

                        .store-total-container .store-total-icon {
                            top: 45%;
                            right: 8%;
                            font-size: 65px;
                            position: absolute;
                            color: rgba(255, 255, 255, 0.4);
                        }

                        .store-total-container .store-total-item {
                            color: #fff;
                            line-height: 4em;
                            padding: 15px 25px;
                            position: relative;
                        }

                        .store-total-container .store-total-item>div:nth-child(2) {
                            font-size: 46px;
                            line-height: 46px;
                        }

                        .num2 {
                            font-size: 20px;
                            font-weight: bold;
                            line-height: 100%
                        }

                        .store-total-container .store-total-item>div:nth-child(2) {
                            font-size: 26px;
                            line-height: 36px;
                            font-weight: bold;
                        }

                        .store-total-container .store-total-item {
                            line-height: 2em
                        }
</style>
